import React from "react"
import { Link } from "gatsby"
import data from "../../../data/casinos.json"
import logo from '../../images/casinolabbet-logo-small.png'

import "./header.scss"

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      value: ''
    };

    this.handleChange = this.handleChange.bind(this);
  }

  toggleHamburger = () => {
    this.setState(prevState => ({
      active: !prevState.active
    }));
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  render() {
    return (
      <header>
        <div className="wrapper">
          <div className="hamburger">
            {!this.state.active ? (
              <span className="material-icons" onClick={this.toggleHamburger}>menu</span>
            ) : (
              <span className="material-icons" onClick={this.toggleHamburger}>close</span>
            )}
            {this.state.active && (
              <div className="hamburger-menu">
                <div className="menu-wrapper one">
                  <Link to="/" activeClassName="active">Online Casino</Link>
                  <Link to="/nya-casino/" activeClassName="active">Nya Casino</Link>
                  <Link to="/erbjudande/" activeClassName="active">Erbjudande</Link>
                </div>

                <div className="menu-wrapper two">
                  <Link to="/casino-med-snabba-uttag/" activeClassName="active">Casino med Snabba Uttag</Link>
                  <Link to="/casino-med-pay-n-play/" activeClassName="active">Casino med Pay n Play</Link>
                  <Link to="/casino-med-swish/" activeClassName="active">Casino som erbjuder Swish</Link>
                  <Link to="/svenska-casino/" activeClassName="active">Alla Svenska Casino</Link>
                </div>

                <div className="menu-wrapper three">
                  <Link to="/" activeClassName="active">Nyheter</Link>
                  <Link to="/om-casinolabbet/" activeClassName="active">Om Casinolabbet</Link>
                  <Link to="/" activeClassName="active">Kontakta oss</Link>
                  <Link to="/" activeClassName="active">Användarvillkor</Link>
                  <Link to="/" activeClassName="active">Cookiepolicy</Link>
                  <Link to="/" activeClassName="active">Webbplatskarta</Link>
                </div>
              </div>
            )}
          </div>
          <div className="logo">
            <Link to="/">
              <img src={logo} alt="Morten" title="Casinohem" />
            </Link>
          </div>
          <div className="menu">
            <ul>
              <li><Link to="/nya-casino/" activeClassName="active">Nya Casino</Link></li>
              <li><Link to="/erbjudande/" activeClassName="active">Erbjudande</Link></li>
            </ul>
          </div>
          <div className="search">
            <input type="text" placeholder="Sök..." value={this.state.value} onChange={this.handleChange} />
           
            {data.filter((val) => {
              if (this.state.value == "") {
                return
              } else if (val.name.toLocaleLowerCase().includes(this.state.value.toLocaleLowerCase())) {
                return val.name
              }
            }).map((val, key) => {
              return <div key={key} className="search-result">
                <Link to={val.slug}>
                  <div className="img-cropper"> 
                    <img src={val.logo} title={val.name} alt={val.name} />
                  </div>
                  <h4>{val.name}</h4>
                </Link>
              </div>
            })}
          </div>
        </div>
      </header>
    )
  }
}

export default Header