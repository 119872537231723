import React from "react"
import { Link } from "gatsby"

import eighteenPlus from '../../images/other/logotype-18plus.png'
import stodlinjen from '../../images/other/logotype-stodlinjen.png'
import spelpaus from '../../images/other/logotype-spelpaus.png'

import "./footer.scss"

const Footer = () => (
  <footer>
    <div className="footer-columns-wrapper">
      <div className="footer-column one">
        <h4>Kort om Casinolabbet</h4>
        <p>Casinolabbet.se är en jämförelsetjänst vad gäller online casino i Sverige. Vårt mål och vision är att guida våra besökare till ett smartare val.</p>
      </div>
      <div className="footer-column two">
        <h4>Vi står för ansvarsfullt spel</h4>
        <p>Spel om pengar på ett online casino är oerhört kul och spännande. Men det tillkommer också en hel del risker. Man bör aldrig spela med pengar man har lånat eller inte har råd att förlora.</p>
      </div>  
      <div className="footer-column three">
        <h4>Informationssidor</h4>
        <ul>
          <li><Link to="/om-casinolabbet/">Om Casinolabbet</Link></li>
          <li><Link to="/">Kontakta oss</Link></li>
          <li><Link to="/">Integritets- & cookiepolicy</Link></li>
          <li><Link to="/">Webbplatskarta</Link></li>
        </ul>
      </div>
    </div>

    <div className="logotypes-wrapper">
        <div className="logotypes">
          <img src={eighteenPlus} className="eighteen-plus" alt="18+" title="18+" />
          <a href="https://www.stodlinjen.se/" target="_blank"><img src={stodlinjen} className="stodlinjen" alt="Stödlinjen" title="Stödlinjen" /></a>
          <a href="https://www.spelpaus.se/" target="_blank"><img src={spelpaus} className="spelpaus" alt="Spelpaus" title="Spelpaus" /></a>
        </div>
    </div>

    <div className="copyright-wrapper">
      © 2021 Casinolabbet. Alla rättigheter förbehållna.
    </div>
  </footer>
)

export default Footer